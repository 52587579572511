(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbFaq.directive:faqList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbFaq">
       <file name="index.html">
        <faq-list></faq-list>
       </file>
     </example>
   *
   */
  angular
    .module('wbFaq')
    .directive('faqList', faqList);

  function faqList() {
    return {
      restrict: 'E',
      templateUrl: 'wb-faq/directives/faq-list-directive.tpl.html',
      replace: false,
      controllerAs: 'faqList',
      controller: function (Faq) {
        var vm = this;

        vm.faqs = Faq.query();
      }
    };
  }
}());
